<template>
  <q-page>
    <q-tabs
      v-model="showTab"
      active-color="primary"
    >
      <q-tab name="active" icon="fal fa-ticket-alt" label="Active">
        <q-badge floating>
          {{ counts.active }}
        </q-badge>
      </q-tab>
      <q-tab name="inactive" icon="fal fa-stream" label="Available">
        <q-badge floating>
          {{ counts.inactive }}
        </q-badge>
      </q-tab>
      <q-tab name="expired" icon="fal fa-trash-alt" label="Expired">
        <q-badge floating>
          {{ counts.expired }}
        </q-badge>
      </q-tab>
    </q-tabs>
    <q-list>
      <ticket v-for="ticket in filteredTickets" :key="ticket.id" v-bind="ticket" />
    </q-list>
    <div class="cta">
      <button
        class="submit shadow-3"
        @click="buyTicket"
      >
        <q-icon name="fas fa-ticket-alt" /> <div>{{ $t('public_transport_ticket') }}</div>
      </button>
    </div>
  </q-page>
</template>

<script>
import ticket from './ticket.vue'
import { index } from 'api/tickets'
import loading from 'utils/loading'
import { handleErrors } from 'utils/utils'

export default {
  components: { ticket },
  data () {
    return {
      // Default for showTab is active, but in the future if there is no active tickets
      // the default should be switched to inactive/available
      showTab: 'active',
      tickets: []
    }
  },
  computed: {
    filteredTickets () {
      return this.tickets.filter(ticket => ticket.status === this.showTab)
    },
    counts () {
      let active = 0, inactive = 0, expired = 0

      this.tickets.forEach(ticket => {
        if (ticket.status === 'active') active++
        else if (ticket.status === 'inactive') inactive++
        else if (ticket.status === 'expired') expired++
      })

      return { active, inactive, expired }
    }
  },
  async beforeMount () {
    const partner = this.$store.getters.partner
    loading.start({
      message: this.$t('loading.getting.tickets'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })
    try {
      const res = await index()
      this.tickets = res.data
      if (res.data.filter(t => t.status === 'active').length < 1) this.showTab = 'inactive'
    } catch (err) {
      handleErrors(err)
    } finally {
      loading.stop()
    }
  },
  methods: {
    buyTicket () {
      this.$store.commit('ondemand/clear')
      this.$router.push({ name: 'public-transport-form' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.buy-ticket-footer
  padding 10px 10px 64px 10px
  background white
.buy-ticket
  width 100%

.cta
  position relative
  background-color white
  margin 6px 10px
  width 100%
  max-width 600px
  @media (max-width 599px)
    padding 0 6px
    position fixed
    margin 0
    padding 10px
    bottom calc(55px + env(safe-area-inset-bottom))

.submit
  border-radius 3px
  font-size 1.25rem
  font-weight 500
  width 100%
  background-color var(--q-color-primary)
  color white
  text-transform uppercase
  padding 10px
  border none
  cursor pointer
  display grid
  gird-template-rows max-content
  grid-template-columns max-content max-content
  align-items baseline
  justify-content center
  gap 0.5rem
</style>
