<template>
  <q-card class="ticket" :class="status">
    <div class="background" />
    <q-card-section>
      <div class="text-h6">
        {{ ticket_type.name }}
      </div>
      <!-- <div class="text-subtitle2">
        Please see full T&Cs before purchasing to check this ticket is valid for your journey
      </div>
      <q-btn padding="none" flat dense label="Terms & conditions" color="primary" align="left" /> -->
      <q-separator class="q-my-sm" />
      <div class="text-subtitle2">
        {{ $tc('num.adults', 1) }}
      </div>
      <div class="row justify-between">
        <div v-if="status !== 'inactive'" :class="`col q-py-sm ${status === 'expired' ? 'text-negative' : 'text-positive'} text-capitalize`">
          {{ status }}
        </div>
        <div v-else class="q-py-sm">
          {{ $t('bus.ticket.activate_before') }}
          {{ ticket_type.created_at | date }}
        </div>
        <div class="row justify-end">
          <q-btn dense flat :label="$t('bus.ticket.buy_again')" color="primary" @click="buyTicket" />
        </div>
      </div>
      <div v-if="status !== 'expired'" class="q-mt-sm">
        <q-btn v-if="status === 'active'" color="primary" class="full-width" unelevated :label="$t('bus.ticket.show')" :to="`/tickets/${id}`" />
        <q-btn v-if="status === 'inactive'" color="primary" class="full-width" outline unelevated :label="$t('bus.ticket.activate')" :to="`/tickets/${id}`" />
      </div>
    </q-card-section>
    <div class="statusIndicator bg-primary">
      <div v-if="status === 'active'">
        <q-icon name="far fa-mobile" />
      </div>
    </div>
  </q-card>
</template>
<script>
import dt from 'utils/date-time'
export default {
  filters: {
    date (val) {
      return dt.toTicketExpiry(val, { months: 3 })
    }
  },
  props: {
    id: Number,
    status: String,
    // eslint-disable-next-line vue/prop-name-casing
    ticket_type: Object,
    operator: String,
    // eslint-disable-next-line vue/prop-name-casing
    created_at: String
  },
  methods: {
    buyTicket () {
      this.$store.commit('ondemand/clear')
      const user = this.$store.getters['userLookup']
      this.$store.dispatch('ondemand/stash', {
        parameters: { traveller: user, requester: user },
        ticket: this.ticket_type
      })
      this.$router.push({ name: 'ondemand-bus-summary' })
    }
  }
}
</script>
<style lang="stylus" scoped>
.ticket
  background-color #f6f6f6

.expired
  .background
    &:before,&:after
      content ''
      position absolute
      top 0
      bottom 0
      left 0
      right 0
      z-index 0
    &:after
      background-position top
      background-repeat repeat-y
      background-image linear-gradient(10deg, #f6f6f6 55%, #f6f6f620 100%);
    &:before
      background-image url('~assets/Crossed Stripes.svg')
      opacity 0.35
      background-size 100%

.statusIndicator
  position absolute
  top 0
  right 0
  width 42px
  height 42px
  color white
  clip-path polygon(100% 0, 0 0, 100% 100%)
  *
    position absolute
    top 12.5%
    right 12.5%
</style>
