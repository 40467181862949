<template>
  <Particles
    id="tsparticles"
    :options="options"
  />
</template>

<script>
import { colors } from 'quasar'
const { getPaletteColor } = colors

export default {
  name: 'Bubbles',
  props: {
    colour: String
  },
  data () {
    return {
      options: {
        autoPlay: true,
        background: {
          color: { value: '#fff' }
        },
        particles: {
          color: { value: 'rgb(255,255,255)' },
          collisions: {
            enable: false
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 0.2,
            straight: false
          },
          fullScreen: {
            enable: false
          },
          number: {
            density: {
              enable: true,
              value_area: 200
            },
            value: 80
          },
          opacity: {
            value: 0.4
          },
          shape: {
            type: 'circle'
          },
          size: {
            random: true,
            value: 20
          }
        }
      }
    }
  },
  mounted () {
    this.options.background.color.value = getPaletteColor(this.colour.toLowerCase())
  }
}
</script>

<style lang="stylus" scoped>
</style>
