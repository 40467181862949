<template>
  <q-card class="ticket-container bg-white">
    <q-card-section class="qr-code">
      <img :src="ticket.qr_code_url">
    </q-card-section>
    <q-card-section align="center">
      <h5>{{ ticket.ticket_type.name }}</h5>
    </q-card-section>
    <q-separator />
    <q-card-section class="row">
      <div class="col-6 adults-price">
        {{ $tc('num.adults', ticket.adults) }}
      </div>
      <div class="col-6 adults-price" align="right">
        {{ $n(ticket.price/100, 'currency') }}
      </div>
    </q-card-section>
    <q-separator />
    <q-card-section align="center" class="valid-from">
      {{ $t('tickets.valid_from') }}<br>
      {{ ticket.valid_from | date }}
    </q-card-section>
    <div class="security-container" :style="`transform: rotate(${wobbleOffset}deg)`">
      <bubbles v-if="security.colour" :colour="security.colour" class="bubbles" />
      <div class="security-word" :class="{showTime: !showWord}">
        <span class="word">{{ security.word }}</span>
        <span class="time">{{ getTime() }}</span>
      </div>
    </div>
    <q-card-section align="center">
      {{ $t('tickets.expires_in') }}<br>
      {{ timeRemaining }}
    </q-card-section>
    <q-card-section align="center">
      <div class="ticket-id">
        {{ ticket.ticket_reference }}
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { activate, details } from 'api/tickets'
import date from 'utils/date-time'
import { toLocalISO } from 'utils/timezoneFilter'
import { Notify } from 'quasar'
import Bubbles from '../Bubbles'
import i18n from 'i18n'
const { newDate, toMediumDateTime, getDateDiff, toCivilTime } = date

if (window.DeviceOrientationEvent && typeof window.DeviceOrientationEvent.requestPermission === 'function') {
  window.DeviceOrientationEvent.requestPermission().then(res => {
    console.log('Asked for Orientation permssion, got: ', res)
  })
} else {
  console.log('Orientation permission not necessary')
}

export default {
  components: { Bubbles },
  filters: {
    date (val) {
      const toLondon = toLocalISO(val, 'Europe/London')
      return toMediumDateTime(toLondon)
    }
  },
  data () {
    return {
      ticket: {
        qr_code_url: '',
        name: '',
        adults: null,
        price: '',
        valid_from: '',
        word: '',
        valid_to: '',
        ticket_reference: '',
        ticket_type: {}
      },
      security: {
        colour: null
      },
      seconds: '',
      timerInterval: null,
      countSeconds: null,
      showWord: true,
      wobbleOffset: 0
    }
  },
  computed: {
    timeRemaining () {
      if (!this.ticket.valid_to) return

      const hours = Math.floor(this.seconds / 3600)
      const minutes = ((this.seconds % 3600) - (this.seconds % 60)) / 60
      const secondsLeft = this.seconds % 60

      return `${hours}h : ${minutes}m : ${secondsLeft}s`
    }
  },
  async beforeRouteEnter (to, from, next) {
    const ticketId = to.params.reference

    try {
      await activate(ticketId)
      next()
    } catch (err) {
      next({ name: 'Tickets' })
      Notify.create(i18n.t('error.activation_failed'))
      next()
    }
  },
  async beforeMount () {
    const now = newDate()
    const ticketId = this.$route.params.reference
    const { data } = await details(ticketId)
    this.ticket = data.ticket
    this.security = data.security
    if (!data.ticket.qr_code_url) this.ticket.qr_code_url = 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1024px-QR_code_for_mobile_English_Wikipedia.svg.png'

    const { values: { seconds } } = getDateDiff(this.ticket.valid_to, now, 'seconds')
    this.seconds = Math.floor(seconds)
    this.startTimer()

    window.addEventListener('deviceorientation', (event) => {
      const max = 25
      this.wobbleOffset = Math.max(max * -1, Math.min(max, event.gamma)) * -1
    })
  },
  methods: {
    startTimer () {
      this.countSeconds = setInterval(() => (this.seconds -= 1), 1000)
      this.timerInterval = setInterval(() => (this.showWord = !this.showWord), 3000)
    },
    getTime () {
      return toCivilTime(newDate())
    }
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.timerInterval)
    clearInterval(this.countSeconds)
    next()
  }
}
</script>

<style lang="stylus" scoped>
.ticket-container
  overflow hidden
  width 100vw
  height calc(100vh - 64px)
  margin 0
  @media (min-width: 768px)
    width 80vw
    max-width 600px
    margin 0 auto

img
  width 66%

.qr-code
  display flex
  align-items center
  justify-content center

.q-card__section
  margin 0
  padding 5px 16px

.ticket-id
  background #ccc
  border-radius 10px

.bubbles
  position fixed
  padding 0
  height 7vh
  z-index 2
  left 0
  right 0

.security-container
  position relative
  width 200vw
  height 7vh
  max-height 7vh
  margin-left -50vw
  color #000
  z-index 900

.security-word
  position fixed
  width 100vw
  margin-left 50vw
  height 100%
  font-size 200%
  z-index 950
  .word
    opacity 1
  .time
    opacity 0

.security-word .word, .security-word .time
  position absolute
  top 50%
  left 50%
  z-index 990
  transition opacity 0.5s
  transform translateX(-50%) translateY(-50%)

.showTime
  .word
    opacity 0
  .time
    opacity 1

.valid-from
  font-size 140%
  color black

.adults-price
  font-size 110%
  color #555
  font-weight 500
  padding 0 15px

</style>
