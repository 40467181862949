<template>
  <component :is="component" />
</template>

<script type="text/javascript">
import { hasPermission } from 'utils/authentication'
import userTickets from './user-tickets'
export default {
  name: 'Tickets',
  computed: {
    component () {
      if (hasPermission('can.book.for.anyone')) {
        console.log(process.env.NODE_ENV === 'development' ? 'Backoffice Tickets does not exist yet, showing the UI for a user instead' : null)
      }
      return userTickets
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 1024px
</style>
